<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">{{$route.params.emailId > 0 ? 'Edit' : 'Add'}} Email Configuration</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="lg:grid grid-cols-2 gap-4" >
      <div class="">
        <div class=" card rounded-xl bg-white p-4 my-4 ">
          <div class="mb-4 ">
            <TextField
              :inputId="'jobtitlename2'"
              :inputext="sendObj.emailLabel"
              :fieldError="errObj.emailLableErr !== ''"
              :placholderText="`Email Label`"
              :lableText="'Email Label'"
              :autoFocus="false"
              @keyPressAction="errObj.emailLableErr = ''"
              @inputChangeAction="(data) => sendObj.emailLabel = data"  />

              <p class="text-error heading-7">{{errObj.emailLableErr}}</p>
          </div>
          <div class="flex items-center">
            <label class="cu_switch cursor-pointer mr-3" :class="!sendObj.isActive ? 'ml-2' : ''">
              <input type="checkbox" v-model="sendObj.isActive">
              <span class="cu_slider cu_round"></span>
            </label>
            <p class="text-gray3 pl-1 cursor-pointer" @click="sendObj.isActive=!sendObj.isActive">{{sendObj.isActive ? 'Email is Active' : 'Email is Inactive'}}</p>
          </div>
        </div>
        <div class=" card rounded-xl bg-white p-4 my-4 ">
          <p class="mb-2 text-text2">Login Credentials:</p>
          <div class="grid grid-cols-2 gap-4">
            <div class="mb-4 ">
              <TextField
                :inputId="'jobtitlename3'"
                :inputext="sendObj.emailId"
                :fieldError="errObj.emailAddErr !== ''"
                :placholderText="`Email Address`"
                :lableText="'Email Address'"
                :autoFocus="false"
                @blurAction="emailHandler(sendObj.emailId)"
                @keyPressAction="errObj.emailAddErr = ''"
                @inputChangeAction="(data) => sendObj.emailId = data"  />
  
                <p class="text-error heading-7">{{errObj.emailAddErr}}</p>
            </div>
            <div class="mb-4 ">
              <TextField
                inputId="'otpinputvalueid1'"
                :inputType="'password'"
                :showcharLimit="false"
                :inputext="sendObj.emailPassword"
                :placholderText="`Password`"
                :lableText="'Password'"
                :autoFocus="false"
                @inputChangeAction="(data) => sendObj.emailPassword = data"  />
            </div>
          </div>
        </div>
        <div class=" card rounded-xl bg-white p-4 mt-4 ">
          <p class="mb-2 text-text2">SMTP Setting:</p>
          <div class="mb-4 ">
            <TextField
              :inputId="'jobtitlename4'"
              :inputext="sendObj.smtpHost"
              :fieldError="errObj.smtpHostErr !== ''"
              :placholderText="`SMTP Host`"
              :lableText="'SMTP Host'"
              :autoFocus="false"
              @keyPressAction="errObj.smtpHostErr = ''"
              @inputChangeAction="(data) => sendObj.smtpHost = data"  />
              <p class="text-error heading-7">{{errObj.smtpHostErr}}</p>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div class="mb-4 ">
              <TextField
                :inputId="'jobtitlename1'"
                :inputext="sendObj.smtpPort"
                :fieldError="errObj.smtpPortErr !== ''"
                :placholderText="`SMTP Port`"
                :lableText="'SMTP Port'"
                :autoFocus="false"
                @keyPressAction="errObj.smtpPortErr = ''"
                @inputChangeAction="(data) => sendObj.smtpPort = data"  />
                <p class="text-error heading-7">{{errObj.smtpPortErr}}</p>
            </div>
            <div class="mb-4 sm:mb-0">
              <Dropdown 
                :inputext="sendObj.smtpEncryption" 
                :inputId="'jobloactioninput'"
                :lableText="'SMTP Encryption'"
                :placholderText="`SMTP Encryption`"
                :showArrow="true"
                :setReadOnly="true"
                :keyName="'name'"
                :listId="'value'"
                :items="smtpList"
                @selectNewForVal="selectJobAddress"
                />
              <p v-if="errObj.smtpEncErr !== ''" class="text-error heading-7">{{errObj.smtpEncErr}}</p>
            </div>
          </div>
          <div class="mt-2">
            <p class=" text-text2">Test SMTP</p>
            <p class="heading-6 text-gray4">Send test email to make sure that your SMTP settings are set correctly</p>
            <div class="mb-4 grid grid-cols-2 items-center">
              <div >
                <TextField
                  :inputId="'jobtitlename55'"
                  :inputext="testObj.to"
                  :fieldError="testEmailSendErr !== ''"
                  :placholderText="`Test Address`"
                  :lableText="''"
                  :autoFocus="false"
                  @blurAction="emailHandler2(testObj.to)"
                  @keyPressAction="testEmailSendErr = ''"
                  @inputChangeAction="(data) => testObj.to = data"  />
                  <p class="text-error heading-7">{{testEmailSendErr}}</p>
              </div>
              <!-- <div class="flex justify-end">
                <Button :disabled="isBtnDisable" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Test Email'" @buttonAction="sendTestEmail()"/>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class=" card rounded-xl bg-white p-4 my-4 ">
          <div class=" col-span-2 mb-4">
            <p class="label_css">Email Signature</p>
              <Editor v-model="sendObj.emailSignature" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end card rounded-xl bg-white p-4 mt-4">
      <div class="text-rigth flex gap-2">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
          <Button v-if="isEmailVarified" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="crateJob()"/>
          <Button v-if="!isEmailVarified" :disabled="isBtnDisable" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Test Email'" @buttonAction="sendTestEmail()"/>
      </div>
    </div>
   </div>
</template>
<script>
import {validateEmail} from '@/utils/validation.js'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import Editor from '@/View/components/editor.vue'
import deboucneMixin from '@/mixins/debounce.js'
import {getEncryptedPassword} from '@/utils/encrytPassword'
export default {
  name: "customer",
  components: {
    Button,
    Editor,
    TextField,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      isInitialLoad: true,
      isEmailVarified: false,
      isBtnDisable: false,
      smtpList: [
        {name: 'No Encryption', value: 1},
        {name: 'SSL', value: 2},
        {name: 'TLS', value: 3},
      ],
      testEmailSendErr: '',
      emailDetail: null,
      errObj: {
        emailLableErr: '',
        emailAddErr: '',
        smtpHostErr: '',
        smtpPortErr: '',
        smtpEncErr: '',
      },
      sendObj: {
        emailConfigId: 0,
        emailLabel: '',
        isActive: true,
        emailId: '',
        emailPassword: '',
        imapHost: '',
        imapPort: '',
        imapEncryption: '',
        smtpHost: '',
        smtpPort: '',
        smtpEncryiptionId: 0,
        smtpEncryption: '',
        emailSignature: '',
      },
      testObj: {
        to: '',
        emailConfigId: 0,
        emailLabel: '',
        isActive: true,
        emailId: '',
        emailPassword: '',
        imapHost: '',
        imapPort: '',
        imapEncryption: '',
        smtpHost: '',
        smtpPort: '',
        smtpEncryiptionId: 0,
        smtpEncryption: '',
        emailSignature: '',
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    '$route.params.emailId': function(newVal) {
      if (newVal > 0) {
        this.isEmailVarified = true;
      } else {
        this.isEmailVarified = false;
      }
    },
    'sendObj.emailId': function(newValue) {
      this.checkFieldChanges('emailId', newValue);
    },
    'sendObj.emailPassword': function(newValue) {
      this.checkFieldChanges('emailPassword', newValue);
    },
    'sendObj.smtpHost': function(newValue) {
      this.checkFieldChanges('smtpHost', newValue);
    },
    'sendObj.smtpPort': function(newValue) {
      this.checkFieldChanges('smtpPort', newValue);
    },
    'sendObj.smtpEncryption': function(newValue) {
      this.checkFieldChanges('smtpEncryption', newValue);
    }
  },
  mounted() {
    if (this.sendObj.emailId === '' || this.sendObj.emailPassword === '' || this.sendObj.smtpHost === '' || this.sendObj.smtpPort === '' || this.sendObj.smtpEncryption === '') {
      this.isBtnDisable = true
    } else {
      this.isBtnDisable = false
    }
    if (this.$route.params.emailId > 0) {
      this.isEmailVarified = true
      this.getCustLeadInfo(parseInt(this.$route.params.emailId))
    }
    setTimeout(() => {
      this.isInitialLoad = false;
    }, 4000);
  },
  beforeDestroy() {
  },
  methods: {
    checkFieldChanges(fieldName, newValue) {
      if (this.$route.params.emailId > 0) {
        // Check if any of the relevant fields have changed
        const hasChanged =
          (fieldName === 'emailId' && newValue !== this.emailDetail?.emailId) ||
          (fieldName === 'emailPassword' && newValue !== this.emailDetail?.emailPassword) ||
          (fieldName === 'smtpHost' && newValue !== this.emailDetail?.smtpHost) ||
          (fieldName === 'smtpPort' && newValue !== this.emailDetail?.smtpPort) ||
          (fieldName === 'smtpEncryption' && newValue !== this.emailDetail?.smtpEncryption);

        if (hasChanged) {
          this.isEmailVarified = false;
        }
      }
      this.isBtnDisable =
        this.sendObj.emailId === '' ||
        this.sendObj.emailPassword === '' ||
        this.sendObj.smtpHost === '' ||
        this.sendObj.smtpPort === '' ||
        this.sendObj.smtpEncryption === '';
    },
    selectJobAddress (data) {
      if (data !== null) {
        this.sendObj.smtpEncryiptionId = data.value
        this.sendObj.smtpEncryption = data.name
        this.errObj.smtpEncErr = ''
      } else {
        this.sendObj.smtpEncryiptionId = 0
        this.sendObj.smtpEncryption = ''
        this.errObj.smtpEncErr = ''
      }
    },
    emailHandler2 (emailId) {
      this.testEmailSendErr = this.emailValidation(emailId)
    },
    emailHandler (emailId) {
      this.errObj.emailAddErr = this.emailValidation(emailId)
    },
    emailValidation (emailId) {
      if (emailId === '') {
        return 'Email address is required'
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
    sendTestEmail () {
      let isValid = true
      if (this.testObj.to === '') {
        this.testEmailSendErr = 'Test Email is required'
        isValid = false
      }
      if (this.testObj.to !== '') {
        if (validateEmail(this.testObj.to) === false) {
          this.testEmailSendErr = 'Test Email address is invalid'
          isValid = false
        }
      }
      if (isValid) {
        // console.log('a')
        if (this.sendObj.emailPassword !== '') {
          this.testObj.emailPassword = getEncryptedPassword(this.sendObj.emailPassword)
        }
        this.testObj.emailConfigId = this.sendObj.emailConfigId
        this.testObj.emailLabel = this.sendObj.emailLabel
        this.testObj.isActive = this.sendObj.isActive
        this.testObj.emailId = this.sendObj.emailId
        this.testObj.imapHost = this.sendObj.imapHost
        this.testObj.imapPort = this.sendObj.imapPort
        this.testObj.imapEncryption = this.sendObj.imapEncryption
        this.testObj.smtpHost = this.sendObj.smtpHost
        this.testObj.smtpPort = this.sendObj.smtpPort
        this.testObj.smtpEncryiptionId = this.sendObj.smtpEncryiptionId
        this.testObj.smtpEncryption = this.sendObj.smtpEncryption
        this.testObj.emailSignature = this.sendObj.emailSignature
        this.$store.dispatch('SetLoader', {status: true, message: 'Sending test email to ' + this.testObj.to})
        MyJobApp.SendTestEmail(
          this.testObj,
          (response) => {
            this.isEmailVarified = true
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
              this.isEmailVarified = false
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      }
    },
    crateJob () {
      let isValid = true
      if (this.sendObj.emailLabel.trim() === '') {
        this.errObj.emailLableErr = 'Email label is required'
        isValid = false
      }
      if (this.sendObj.emailId === '') {
        this.errObj.emailAddErr = 'Email label is required'
        isValid = false
      }
      if (this.sendObj.emailId !== '') {
        if (validateEmail(this.sendObj.emailId) === false) {
          this.errObj.emailAddErr = 'Email address is invalid'
          isValid = false
        }
      }
      if (this.sendObj.smtpHost === '') {
        this.errObj.smtpHostErr = 'SMTP Host is required'
        isValid = false
      }
      if (this.sendObj.smtpPort === '') {
        this.errObj.smtpPortErr = 'SMTP Port is required'
        isValid = false
      }
      if (this.sendObj.smtpEncryiptionId === 0) {
        this.errObj.smtpEncErr = 'SMTP Encryption is required'
        isValid = false
      }
      if (isValid) {
        if (this.sendObj.emailPassword !== '') {
          this.sendObj.emailPassword = getEncryptedPassword(this.sendObj.emailPassword)
        }
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.SaveEmailConf(
          this.sendObj,
          (response) => {
              this.$router.push({name: 'EmailconfigurationDetail', params: {emailId: response.Data}})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      }
      
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
    getCustLeadInfo(custId) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.GetEmailConfDetail(
        custId,
        (response) => {
          this.emailDetail = response.Data
            this.sendObj.emailConfigId = response.Data.emailConfigId
            this.sendObj.emailLabel = response.Data.emailLabel
            this.sendObj.isActive = response.Data.isActive
            this.sendObj.emailId = response.Data.emailId
            this.sendObj.emailPassword = ''
            this.sendObj.imapHost = response.Data.imapHost
            this.sendObj.imapPort = response.Data.imapPort
            this.sendObj.imapEncryption = response.Data.imapEncryption
            this.sendObj.smtpHost = response.Data.smtpHost
            this.sendObj.smtpPort = response.Data.smtpPort
            // this.sendObj.smtpEncryiptionId = response.Data.smtpEncryiptionId
            for (let index = 0; index < this.smtpList.length; index++) {
              if (response.Data.smtpEncryption.toLocaleLowerCase() === this.smtpList[index].name.toLocaleLowerCase()) {
                this.sendObj.smtpEncryiptionId = this.smtpList[index].value
              }
            }
            this.sendObj.smtpEncryption = response.Data.smtpEncryption
            this.sendObj.emailSignature = response.Data.emailSignature
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
};
</script>
<style scoped>

</style>